import {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTasks, faClock, faFile, faUsers, faBell, faEnvelopeOpenText, faTint, faMoneyBillAlt, faFastForward, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';

const Contact = ({setShowContact}) => {
	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [email, setEmail] = useState();
	const [message, setMessage] = useState();

	const tabClick = () => {
		const Contact_form = document.getElementById('Contact_form');
		const Contact_info = document.getElementById('Contact_info');

		const contactForm = document.getElementById('contact-form');
		const contactInfo = document.getElementById('contact-info');

		contactForm.style.display = 'block';
		contactInfo.style.display = 'none';

		Contact_form.classList.add('active');

		Contact_info.classList.remove('active');	
	}

	const tabClick2 = () => {
		const Contact_form = document.getElementById('Contact_form');
		const Contact_info = document.getElementById('Contact_info');

		const contactForm = document.getElementById('contact-form');
		const contactInfo = document.getElementById('contact-info');

		contactForm.style.display = 'none';
		contactInfo.style.display = 'block';

		Contact_form.classList.remove('active');

		Contact_info.classList.add('active');	
	}

	const submit = async(e) => {
		e.preventDefault();
		console.log(firstName, lastName, email, message);

		window.open(`https://wa.me/0727643203/?text=Name:${firstName} ${lastName}\n Email:${email}\n\n\n\n ${message}`, '_blank');
	}

	return(
		<>
			<div className="overlay">
				<div className="Contact-modal">
					<span style={{float: 'right', cursor: 'pointer'}} onClick={()=>setShowContact(false)}>X</span><br />
					<ul class="nav nav-tabs">
					  <li class="nav-item">
					    <a class="nav-link active" aria-current="page" href="#" id="Contact_form" onClick={()=>tabClick()}>Contact form</a>
					  </li>
					  <li class="nav-item">
					    <a class="nav-link" href="#" id="Contact_info" onClick={()=>tabClick2()}>Contact infomation</a>
					  </li>
					</ul>
					<form id="contact-form" onSubmit={(e) => submit(e)}>
					  <div class="row">
					    <div class="col-md-6">
					      <input type="text" class="form-control" placeholder="First name" value={firstName} onChange={(e)=>setFirstName(e.target.value)} />
					    </div>
					    <div class="col-md-6">
					      <input type="text" class="form-control" placeholder="Last name" value={lastName} onChange={(e)=>setLastName(e.target.value)} />
					    </div>
					  </div><br />
					  <div class="form-group">
					    <label for="exampleInputPassword1">Email</label>
					    <input type="emai" class="form-control" id="exampleInputPassword1" placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)} />
					  </div><br />
					  <div class="form-group">
					    <label for="exampleFormControlTextarea1">Message</label>
					    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e)=>setMessage(e.target.value)} >{message}</textarea>
					  </div><br />
					  <button type="submit" class="btn btn-dark">Submit</button>
					</form>

					<div id="contact-info" className="contact-info" style={{display: 'none'}}>
						<div className="footer-contact">
		                  <div className="row">
		                    <div className="col-md-1">
		                      <FontAwesomeIcon icon={faPhone} className="icon-top" />
		                    </div>
		                    <div className="col-md-11">
		                      <p>072 764 3203</p>
		                    </div>
		                  </div>
		                </div>

		                <div className="footer-contact">
		                  <div className="row">
		                    <div className="col-md-1">
		                      <FontAwesomeIcon icon={faEnvelope} className="icon-top" />
		                    </div>
		                    <div className="col-md-11">
		                      <p>infinity@te-amo.co.za</p>
		                    </div>
		                  </div>
		                </div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Contact;