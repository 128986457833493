import {useState} from 'react';
import Contact from './Contact.js';
import Nav from './Nav';
import GoogleMapReact from 'google-map-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import GoogleMap from 'google-map-react';
import { SocialIcon } from 'react-social-icons';

const AnyReactComponent = ({ text, showBubble, setShowBubble }) => 
<div style={{ position: 'relative', transform: 'translate(-50%, -100%)' }}>
	{
		showBubble?
			<div className="bubble shadow-sm" >
				<p>{text}</p>
			</div>
		:<p>'sdsd'</p>
	}
	<FontAwesomeIcon icon={faMapMarkerAlt} className="icon-top-2" onClick={()=>setShowBubble(true)} style={{ fontSize: '24px', color: 'lightblue' }} />
</div>;

const Locations = () => {
	const [showContact, setShowContact] = useState(false);

	const [showBubble, setShowBubble] = useState(true);

	const defaultProps = {
		    center: {
		      lat: -29.8500,
      		  lng: 30.8900,
		    },
		    zoom: 11
		  };

	function createMapOptions(maps) {
	  return {
	    zoomControlOptions: {
	      position: maps.ControlPosition.RIGHT_CENTER,
	      style: maps.ZoomControlStyle.SMALL
	    },
	    mapTypeControlOptions: {
	      position: maps.ControlPosition.TOP_RIGHT
	    },
	    mapTypeControl: true
	  };
	}

	return (
		<>
			<div className="App">
		      {
		        showContact?<Contact setShowContact={setShowContact}/>:''
		      }
		      <Nav setShowContact={setShowContact} />

		      {/*<div className="row">*/}
		      	{/*<div className="col-md-4">*/}
			      <div className="locations-nav">
			      	<h1>Find your location.</h1>

			      	<div className="locations-location">
			      		<h3>Marlven</h3>
			      		<p>2 Nelson Cres, Malvern, Queensburgh, 4093</p><br />

			      		{/*<button className="btn btn-info text-light" onClick={()=>setShowBubble(true)}>View in Map</button>*/}
			      		<SocialIcon url="https://www.instagram.com/infinity_car_wash_/" bgColor="#0dcaf0" />&#160;
		                <SocialIcon url="https://www.tiktok.com/@infinity.carwash" bgColor="#0dcaf0" />&#160;
		                <SocialIcon url="https://m.facebook.com/61550704750860/" bgColor="#0dcaf0" />
			      	</div>

			      	<div className="locations-location">
			      		<h3>Pinetown</h3>
			      		<p>6 underwood road pinetown</p><br />

			      		{/*<span className="btn btn-info text-light" onClick={()=>setShowBubble(true)}>View in Map</span>*/}
			      		<SocialIcon url="https://www.instagram.com/infinity_car_wash_/" bgColor="#0dcaf0" />&#160;
		                <SocialIcon url="https://www.tiktok.com/@infinity.carwash" bgColor="#0dcaf0" />&#160;
		                <SocialIcon url="https://m.facebook.com/61550704750860/" bgColor="#0dcaf0" />
			      	</div>

			      </div>
			    {/*</div>*/}

			    {/*<div className="col-md-8">*/}
			    	<div className="map">
				    	<div style={{ height: '100vh', width: '100%' }}>
					      <GoogleMap
					        bootstrapURLKeys={{ key: "AIzaSyDENioKxXlEV1wxt30v4YDNo1QuvOryxKU" }}
					        center={defaultProps.center}
					        zoom={defaultProps.zoom}
					        options={{ scrollwheel: false}}
					      	>
					        <AnyReactComponent
					          lat={-29.880035}
					          lng={30.926458}
					          text="2 Nelson Cres, Malvern, Queensburgh, 4093"
					          showBubble={showBubble}
					          setShowBubble={setShowBubble}
					        />

					        <AnyReactComponent
					          lat={-29.821280}
					          lng={30.860970}
					          text="6 underwood road pinetown"
					          showBubble={showBubble}
					          setShowBubble={setShowBubble}
					        />
					      </GoogleMap>
					    </div>
				    </div>
			    {/*</div>*/}
		      {/*</div>*/}

		    </div>
		</>
	)
}

export default Locations;