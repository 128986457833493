//import logo from './logo.svg';
import logo from '../20240710_1130142.jpg';
import Mlogo from '../PngItem_122191.png';
import hero from '../Untitled-2.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTasks, faClock, faFile, faUsers, faBell, faEnvelopeOpenText, faTint, faMoneyBillAlt, faFastForward, faMapMarkerAlt, faPhone, faBars } from '@fortawesome/free-solid-svg-icons';
import { SocialIcon } from 'react-social-icons';
import {createBrowserRouter, createRoutesFromElements, Route, Outlet, RouterProvider, Link} from 'react-router-dom';

/*import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";*/

import {SlideshowLightbox} from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';

import Img1 from '../Capture.PNG';
import Img2 from '../Capture2.PNG';
import Img3 from '../Capture3.PNG';
import Img4 from '../Capture4.PNG';
import Img5 from '../Capture5.PNG';
import Img6 from '../Capture6.PNG';
import Img7 from '../Capture7.PNG';
import Img8 from '../Capture8.PNG';
import Img9 from '../Capture9.PNG';
import Img10 from '../Capture10.PNG';
import Img11 from '../Capture11.PNG';
import Img12 from '../Capture12.PNG';
import Img13 from '../Capture13.PNG';
import Img14 from '../Capture14.PNG';
import Img15 from '../Capture15.PNG';
import Img16 from '../Capture16.PNG';
import Img17 from '../Capture17.PNG';
import Img18 from '../Capture18.PNG';
import Img19 from '../Capture19.PNG';
import Img20 from '../Capture20.PNG';
import Img21 from '../Capture21.PNG';
import Img22 from '../Capture22.PNG';
import Img23 from '../Capture23.PNG';
import Img24 from '../Capture24.PNG';
import Img25 from '../Capture25.PNG';
import Img26 from '../Capture26.PNG';
import Img27 from '../Capture27.PNG';

import prices1 from '../imgs/IMG-20240722-WA0010.jpg';
import prices2 from '../imgs/IMG-20240802-WA0004.jpg';

import phone from '../pexels-alina-komarevska-473904828-15793245.png';

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"

import Contact from './Contact.js';
import Nav from './Nav';

const showNav = () => {
    const mobileNav = document.getElementById('mobile-nav');
    mobileNav.style.left = '0';
}

const removeNav = () => {
    const mobileNav = document.getElementById('mobile-nav');
    mobileNav.style.left = '-100%';
}

function Home() {

  const [showContact, setShowContact] = useState(false);

  return (
    <div className="App">
      {
        showContact?<Contact setShowContact={setShowContact}/>:''
      }
      <Nav setShowContact={setShowContact} />

      <section className="hero">
        <div className="hero-sec">
          <div className="hero-content">
            <img src={Mlogo} className="Mlogo" />
            <ScrollAnimation animateIn='bounceInRight'
  animateOut='bounceOutLeft'>

            <div className="mobile-hero-text">
                <h1>Infinity Car<br /> Wash</h1>
                <p>We give that infinity shine.</p>
                <Link to="/gallery"><span className="btn btn-light btn-lg no-mobile">See more!!!</span></Link> <Link to="/locations"><span className="btn btn-dark btn-lg"><span className="no-mobile">Find Your</span> Location</span></Link>
            </div>
            </ScrollAnimation>
          </div>
        </div>
      </section>

      <section className="first-sec">
        <div className="container">
          <div className="row">
            <div className='col-md-4'>
              <ScrollAnimation animateIn='fadeIn'>
                <div className='icon-card'>
                  <div className="icon-top-bg">
                    <FontAwesomeIcon icon={faTint} className="icon-top" />
                  </div>
                  <h1>Clean</h1>
                  <p>The best in the game</p>
                </div>
              </ScrollAnimation>
            </div>
            <div className='col-md-4'>
              <ScrollAnimation animateIn='fadeIn' delay={100}>
              <div className='icon-card'>
                <div className="icon-top-bg">
                  <FontAwesomeIcon icon={faFastForward} className="icon-top" />
                </div>
                <h1>Fast</h1>
                <p>Quick Services</p>
              </div>
              </ScrollAnimation>
            </div>
            <div className='col-md-4'>
              <ScrollAnimation animateIn='fadeIn' delay={200}>
              <div className='icon-card'>
                <div className="icon-top-bg">
                  <FontAwesomeIcon icon={faMoneyBillAlt} className="icon-top" />
                </div>
                <h1>Affordable</h1>
                <p>Affordable prices</p>
              </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section className="second-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                <h1>GET IN TOUCH</h1>
                <p>Contact us for any enquiries (something like that)</p>
                <button className="btn btn-dark btn-lg" onClick={()=>setShowContact(true)}>Contact</button>
              </div>
              <div className="col-md-2">
                <ScrollAnimation animateIn='wobble' initiallyVisible={true}>
                    <img src={phone} className="w-small-banner" />
                </ScrollAnimation>
              </div>
            </div>
          </div>
      </section>

      <section className="third-sec">
        <div className="container">
          <h1>Image Gallery</h1>
        </div>
        <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
            <img className="rounded w-small" src={Img1} />
            <img className="rounded w-small" src={Img2} />  
            <img className="rounded w-small" src={Img3} />
            <img className="rounded w-small" src={Img4} />
            <img className="rounded w-small" src={Img5} />
            <img className="rounded w-small" src={Img6} />
            <img className="rounded w-small" src={Img7} />
            <img className="rounded w-small" src={Img8} />
            <img className="rounded w-small" src={Img9} />
            <img className="rounded w-small" src={Img10} />
            <img className="rounded w-small" src={Img11} />
            <img className="rounded w-small" src={Img12} />
            <img className="rounded w-small" src={Img13} />
            <img className="rounded w-small" src={Img14} />
            <img className="rounded w-small" src={Img15} />
            <img className="rounded w-small" src={Img16} />
            <img className="rounded w-small" src={Img17} />
            <img className="rounded w-small" src={Img18} />
            <img className="rounded w-small" src={Img19} />
            <img className="rounded w-small" src={Img20} />
            <img className="rounded w-small" src={Img21} />
            <img className="rounded w-small" src={Img22} />
            <img className="rounded w-small" src={Img23} />
            <img className="rounded w-small" src={Img24} />
            <img className="rounded w-small" src={Img25} />
            <img className="rounded w-small" src={Img26} />
            <img className="rounded w-small" src={Img27} />
        </SlideshowLightbox>
      </section>

      <section className="fourth-sec">

        <div className="container">
          <h1>Prices</h1><br />
          <div className="row">
            <div className="col-md-6">
              <ScrollAnimation animateIn='flipInY' animateOut='flipOutY'>
                <div className="text-card">
                  <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                    <img src={prices1} className="prices" />
                  </SlideshowLightbox>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-md-6">
              <div className="text-card bg-dark text-light">
                <h1>Regular washes</h1>
              </div>
            </div>

            <div className="col-md-6">
              <div className="text-card bg-warning">
                <h1>Meguiar's washes</h1>
              </div>
            </div>
            <div className="col-md-6">
              <ScrollAnimation animateIn='flipInY' animateOut='flipOutY'>
                <div className="text-card">
                <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                  <img src={prices2} className="prices" />
                </SlideshowLightbox>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div><br /><br /><br />

      </section>

      <footer className="footer">
        <div className="container">
          <div className="footer-top-sec">
            <div className="row">
              <div className="col-md-10">
                <img src={logo} className="App-logo-footer" alt="logo" />
              </div>

              <div className="col-md-2">
                <SocialIcon url="https://www.instagram.com/infinity_car_wash_/" bgColor="#333" />&#160;
                <SocialIcon url="https://www.tiktok.com/@infinity.carwash" bgColor="#333" />&#160;
                <SocialIcon url="https://m.facebook.com/61550704750860/" bgColor="#333" />
              </div>
            </div>
          </div>


          <div className="footer-bottom-sec">
            <div className="row">
              <div className="col-md-8">
                <h3>About Infinity Car Wash</h3>
                <p className="footer-about">Welcome to Infinity Car Wash, Meguiar's Approved Car Wash! Our state-of-the-art facility was launched in September 2023, our expert team use only the best Meguiar's products and equipment to leave your vehicle looking and feeling its best. As a Meguiar's Approved Car Wash, we meet the highest standards for quality and care, ensuring a superior clean and protection for your vehicle's finish. From quick washes to detailed Auto valets , we offer a range of services to fit your needs and budget.</p>
              </div>
              <div className="col-md-4">

                <div className="footer-contact">
                  <div className="row">
                    <div className="col-md-1">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="icon-top" />
                    </div>
                    <div className="col-md-11">
                      <p>2 Nelson Cres, Malvern, Queensburgh, 4093</p>
                    </div>
                  </div>
                </div>

                <div className="footer-contact">
                  <div className="row">
                    <div className="col-md-1">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="icon-top" />
                    </div>
                    <div className="col-md-11">
                      <p>6 underwood road pinetown</p>
                    </div>
                  </div>
                </div>

                <div className="footer-contact">
                  <div className="row">
                    <div className="col-md-1">
                      <FontAwesomeIcon icon={faPhone} className="icon-top" />
                    </div>
                    <div className="col-md-11">
                      <p>072 764 3203</p>
                    </div>
                  </div>
                </div>

                <div className="footer-contact">
                  <div className="row">
                    <div className="col-md-1">
                      <FontAwesomeIcon icon={faEnvelope} className="icon-top" />
                    </div>
                    <div className="col-md-11">
                      <p>infinity@te-amo.co.za</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>
      </footer>
    </div>
  );
}

export default Home;
