import logo from '../20240710_1130142.jpg';
import Mlogo from '../PngItem_122191.png';
import hero from '../Untitled-2.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTasks, faClock, faFile, faUsers, faBell, faEnvelopeOpenText, faTint, faMoneyBillAlt, faFastForward, faMapMarkerAlt, faPhone, faBars } from '@fortawesome/free-solid-svg-icons';
import { SocialIcon } from 'react-social-icons';
import {createBrowserRouter, createRoutesFromElements, Route, Outlet, RouterProvider, Link} from 'react-router-dom';

import {SlideshowLightbox} from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';

import Img1 from '../Capture.PNG';
import Img2 from '../Capture2.PNG';
import Img3 from '../Capture3.PNG';
import Img4 from '../Capture4.PNG';
import Img5 from '../Capture5.PNG';
import Img6 from '../Capture6.PNG';
import Img7 from '../Capture7.PNG';
import Img8 from '../Capture8.PNG';
import Img9 from '../Capture9.PNG';
import Img10 from '../Capture10.PNG';
import Img11 from '../Capture11.PNG';
import Img12 from '../Capture12.PNG';
import Img13 from '../Capture13.PNG';
import Img14 from '../Capture14.PNG';
import Img15 from '../Capture15.PNG';
import Img16 from '../Capture16.PNG';
import Img17 from '../Capture17.PNG';
import Img18 from '../Capture18.PNG';
import Img19 from '../Capture19.PNG';
import Img20 from '../Capture20.PNG';
import Img21 from '../Capture21.PNG';
import Img22 from '../Capture22.PNG';
import Img23 from '../Capture23.PNG';
import Img24 from '../Capture24.PNG';
import Img25 from '../Capture25.PNG';
import Img26 from '../Capture26.PNG';
import Img27 from '../Capture27.PNG';


import Img28 from '../imgs/20230915_082318.jpg';
import Img29 from '../imgs/20230915_082331.jpg';
import Img30 from '../imgs/20230915_093347.jpg';
import Img31 from '../imgs/20230915_093358.jpg';
import Img32 from '../imgs/20230915_111204.jpg';
import Img33 from '../imgs/20230915_111218.jpg';
import Img34 from '../imgs/20230915_140900.jpg';
import Img35 from '../imgs/20230915_140924.jpg';
import Img36 from '../imgs/20230915_140953.jpg';
import Img37 from '../imgs/20231024_114937.jpg';
import Img38 from '../imgs/20231024_120004.jpg';
import Img39 from '../imgs/20231112_114817.jpg';
import Img40 from '../imgs/20231130_142154.jpg';
import Img41 from '../imgs/20231202_170623.jpg';
import Img42 from '../imgs/20231202_170803.jpg';
import Img43 from '../imgs/20231213_165323.jpg';
import Img44 from '../imgs/20231215_103951.jpg';
import Img45 from '../imgs/20231215_104045.jpg';
import Img46 from '../imgs/20231219_145926.jpg';
import Img47 from '../imgs/20231219_152143.jpg';
import Img48 from '../imgs/20231219_152217.jpg';
import Img49 from '../imgs/20240114_175535.jpg';
import Img50 from '../imgs/20240216_123140.jpg';
import Img51 from '../imgs/FB_IMG_1694430722483.jpg';
import Img52 from '../imgs/FB_IMG_1694430725526.jpg';
import Img53 from '../imgs/FB_IMG_1694430728503.jpg';
import Img54 from '../imgs/FB_IMG_1694430787387.jpg';
import Img55 from '../imgs/FB_IMG_1694430828867.jpg';
import Img56 from '../imgs/FB_IMG_1694430834736.jpg';
import Img57 from '../imgs/FB_IMG_1694430870959.jpg';
import Img58 from '../imgs/FB_IMG_1694430873771.jpg';
import Img59 from '../imgs/FB_IMG_1694430909579.jpg';
import Img60 from '../imgs/FB_IMG_1694430911963.jpg';
import Img61 from '../imgs/FB_IMG_1694430946796.jpg';
import Img62 from '../imgs/FB_IMG_1694430949718.jpg';
import Img63 from '../imgs/FB_IMG_1694430952894.jpg';
import Img64 from '../imgs/FB_IMG_1694430999583.jpg';
import Img65 from '../imgs/FB_IMG_1694431002369.jpg';
import Img66 from '../imgs/FB_IMG_1694431037961.jpg';
import Img67 from '../imgs/FB_IMG_1694431041114.jpg';
import Img68 from '../imgs/FB_IMG_1694431082579.jpg';
import Img69 from '../imgs/FB_IMG_1694431085635.jpg';


import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";

import Contact from './Contact';
import Nav from './Nav';

const Gallery = () => {

  const [showContact, setShowContact] = useState(false);

	const showNav = () => {
	    const mobileNav = document.getElementById('mobile-nav');
	    mobileNav.style.left = '0';
	}

	const removeNav = () => {
	    const mobileNav = document.getElementById('mobile-nav');
	    mobileNav.style.left = '-100%';
	}

	return(
		<div className="App">
      {
        showContact?<Contact setShowContact={setShowContact}/>:''
      }

      <Nav setShowContact={setShowContact} />

      <section className="third-sec">
        <div className="container">
          <h1>Image Gallery</h1>
        </div>
        <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
            <img className="rounded w-small" src={Img28} />
            <img className="rounded w-small" src={Img29} />
            <img className="rounded w-small" src={Img30} />
            <img className="rounded w-small" src={Img31} />
            <img className="rounded w-small" src={Img32} />
            <img className="rounded w-small" src={Img33} />
            <img className="rounded w-small" src={Img34} />
            <img className="rounded w-small" src={Img35} />
            <img className="rounded w-small" src={Img36} />
            <img className="rounded w-small" src={Img37} />
            <img className="rounded w-small" src={Img38} />
            <img className="rounded w-small" src={Img39} />
            <img className="rounded w-small" src={Img40} />
            <img className="rounded w-small" src={Img41} />
            <img className="rounded w-small" src={Img42} />
            <img className="rounded w-small" src={Img43} />
            <img className="rounded w-small" src={Img44} />
            <img className="rounded w-small" src={Img45} />
            <img className="rounded w-small" src={Img46} />
            <img className="rounded w-small" src={Img47} />
            <img className="rounded w-small" src={Img48} />
            <img className="rounded w-small" src={Img49} />
            <img className="rounded w-small" src={Img50} />
            <img className="rounded w-small" src={Img51} />
            <img className="rounded w-small" src={Img52} />
            <img className="rounded w-small" src={Img53} />
            <img className="rounded w-small" src={Img54} />
            <img className="rounded w-small" src={Img55} />
            <img className="rounded w-small" src={Img56} />
            <img className="rounded w-small" src={Img57} />
            <img className="rounded w-small" src={Img58} />
            <img className="rounded w-small" src={Img59} />
            <img className="rounded w-small" src={Img60} />
            <img className="rounded w-small" src={Img61} />
            <img className="rounded w-small" src={Img62} />
            <img className="rounded w-small" src={Img63} />
            <img className="rounded w-small" src={Img64} />
            <img className="rounded w-small" src={Img64} />
            <img className="rounded w-small" src={Img65} />
            <img className="rounded w-small" src={Img66} />
            <img className="rounded w-small" src={Img67} />
            <img className="rounded w-small" src={Img68} />
            <img className="rounded w-small" src={Img68} />
            <img className="rounded w-small" src={Img69} />

            <img className="rounded w-small" src={Img1} />
            <img className="rounded w-small" src={Img2} />  
            <img className="rounded w-small" src={Img3} />
            <img className="rounded w-small" src={Img4} />
            <img className="rounded w-small" src={Img5} />
            <img className="rounded w-small" src={Img6} />
            <img className="rounded w-small" src={Img7} />
            <img className="rounded w-small" src={Img8} />
            <img className="rounded w-small" src={Img9} />
            <img className="rounded w-small" src={Img10} />
            <img className="rounded w-small" src={Img11} />
            <img className="rounded w-small" src={Img12} />
            <img className="rounded w-small" src={Img13} />
            <img className="rounded w-small" src={Img14} />
            <img className="rounded w-small" src={Img15} />
            <img className="rounded w-small" src={Img16} />
            <img className="rounded w-small" src={Img17} />
            <img className="rounded w-small" src={Img18} />
            <img className="rounded w-small" src={Img19} />
            <img className="rounded w-small" src={Img20} />
            <img className="rounded w-small" src={Img21} />
            <img className="rounded w-small" src={Img22} />
            <img className="rounded w-small" src={Img23} />
            <img className="rounded w-small" src={Img24} />
            <img className="rounded w-small" src={Img25} />
            <img className="rounded w-small" src={Img26} />
            <img className="rounded w-small" src={Img27} />
        </SlideshowLightbox>
      </section>

      <footer className="footer">
        <div className="container">
          <div className="footer-top-sec">
            <div className="row">
              <div className="col-md-10">
                <img src={logo} className="App-logo-footer" alt="logo" />
              </div>

              <div className="col-md-2">
                <SocialIcon url="https://www.instagram.com/infinity_car_wash_/" bgColor="#333" />&#160;
                <SocialIcon url="https://www.tiktok.com/@infinity.carwash" bgColor="#333" />&#160;
                <SocialIcon url="https://m.facebook.com/61550704750860/" bgColor="#333" />
              </div>
            </div>
          </div>


          <div className="footer-bottom-sec">
            <div className="row">
              <div className="col-md-8">
                <h3>About Infinity Car Wash</h3>
                <p className="footer-about">Welcome to Infinity Car Wash, Meguiar's Approved Car Wash! Our state-of-the-art facility was launched in September 2023, our expert team use only the best Meguiar's products and equipment to leave your vehicle looking and feeling its best. As a Meguiar's Approved Car Wash, we meet the highest standards for quality and care, ensuring a superior clean and protection for your vehicle's finish. From quick washes to detailed Auto valets , we offer a range of services to fit your needs and budget.</p>
              </div>
              <div className="col-md-4">

                <div className="footer-contact">
                  <div className="row">
                    <div className="col-md-1">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="icon-top" />
                    </div>
                    <div className="col-md-11">
                      <p>2 Nelson Cres, Malvern, Queensburgh, 4093</p>
                    </div>
                  </div>
                </div>

                <div className="footer-contact">
                  <div className="row">
                    <div className="col-md-1">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="icon-top" />
                    </div>
                    <div className="col-md-11">
                      <p>6 underwood road pinetown</p>
                    </div>
                  </div>
                </div>

                <div className="footer-contact">
                  <div className="row">
                    <div className="col-md-1">
                      <FontAwesomeIcon icon={faPhone} className="icon-top" />
                    </div>
                    <div className="col-md-11">
                      <p>072 764 3203</p>
                    </div>
                  </div>
                </div>

                <div className="footer-contact">
                  <div className="row">
                    <div className="col-md-1">
                      <FontAwesomeIcon icon={faEnvelope} className="icon-top" />
                    </div>
                    <div className="col-md-11">
                      <p>infinity@te-amo.co.za</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>
      </footer>
    </div>
	);
}

export default Gallery;