import logo from '../20240710_1130142.jpg';
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTasks, faClock, faFile, faUsers, faBell, faEnvelopeOpenText, faTint, faMoneyBillAlt, faFastForward, faMapMarkerAlt, faPhone, faBars } from '@fortawesome/free-solid-svg-icons';

const Nav = ({setShowContact}) => {

	const showNav = () => {
	    const mobileNav = document.getElementById('mobile-nav');
	    mobileNav.style.left = '0';
	}

	const removeNav = () => {
	    const mobileNav = document.getElementById('mobile-nav');
	    mobileNav.style.left = '-100%';
	}

	return(
		<header className="App-header">
	        <div className="row">
	          <div className="col-4">
	            <img src={logo} className="App-logo" alt="logo" />
	          </div>
	          <div className="col-8">
	            <nav className="nav">
	              <ul className="nav-links">
	                <Link to="/"><li>Home</li></Link>
	                <Link to="/gallery"><li>Gallery</li></Link>
	                <Link to="/locations"><li>Locations</li></Link>
	                <span className="btn btn-info" onClick={()=>setShowContact(true)}>Contact</span>
	              </ul>
	            </nav>
	            <FontAwesomeIcon icon={faBars} className="icon-top" onClick={()=>showNav()} />
	          </div>
	        </div>

	        <nav className="mobile-nav" id="mobile-nav">
	          <span onClick={()=>removeNav()}>X</span>
	          <ul className="mobile-nav-links">
	            <Link to="/"><li>Home</li></Link>
	            <Link to="/gallery"><li>Gallery</li></Link>
	            <Link to="/locations"><li>Locations</li></Link>
	            <li onClick={()=>setShowContact(true)}>Contact</li>
	          </ul>
	        </nav>
	    </header>
	);
}

export default Nav;