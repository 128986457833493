import {createBrowserRouter, createRoutesFromElements, Route, Outlet, RouterProvider} from 'react-router-dom';
import Home from './Components/Home';
import Gallery from './Components/Gallery';
import Locations from './Components/Locations';
import Root from './Components/Root';
function App() {

  const router = createBrowserRouter(
      createRoutesFromElements(
          <Route path="/" element={<Root />} >
              <Route index element={<Home />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/locations" element={<Locations />} />
          </Route>
      )
  );

  return (
        <>
          <RouterProvider router={router} />
        </>
  );
}

export default App;
